var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"timeline-modal"},[_c('XModal',{attrs:{"name":"timeline","title":((_vm.selDateTimeLine ? _vm.selDateTimeLine.Date.timeFormat('yyyy-MM-dd') : '') + " " + (_vm.selDateTimeLine ? _vm.selDateTimeLine.userName : '') + "的打卡详情"),"width":"85%","height":"90%","showCrossBtn":true},on:{"opened":_vm.opened}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[(_vm.selDateTime && _vm.selDateTime.length)?_c('div',{staticClass:"soft"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"title"},[_vm._v("时间轴与使用软件")])]),(_vm.selDateTime && _vm.selDateTime.length)?_c('div',{staticClass:"softbox"},_vm._l((_vm.selDateTime),function(item,softind){return _c('div',{key:softind,class:{
              speed_footer: softind % 2 == 0,
              speed_header: softind % 2 !== 0,
            }},[_c('div',{class:[
                'left',
                item.CheckStatus == '签到' ? 'sign_in' : '',
                item.CheckStatus == '签退' ? 'sign_out' : '' ]},[_c('span',{staticClass:"time"},[(item.CheckStatus)?_c('label',[_vm._v(_vm._s(item.CheckStatus)+":")]):_vm._e(),_vm._v(_vm._s(item.LineTime))]),(item.Process && item.Process.length)?_c('ul',{staticClass:"s_l_ul"},_vm._l((item.Process),function(citem,csoftind){return _c('li',{key:csoftind,staticClass:"s_l_li"},[_c('tooltip',{ref:("process-" + csoftind),refInFor:true,staticClass:"i_text",attrs:{"content":citem.PName,"maxWidth":"70%"},on:{"handleClick":function($event){return _vm.handleAppPic(citem)}}}),_c('span',[_vm._v("("+_vm._s((citem.PNamePercent * 100).toFixed(0))+"%)")])],1)}),0):_vm._e()]),_c('div',{staticClass:"right"})])}),0):_c('div',{staticClass:"nodata"},[_c('i',{staticClass:"hiFont hi-wushuju"}),_c('p',[_vm._v("暂无数据")])])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"scree"},[_c('div',{staticClass:"scree_title"},[_c('span',{staticClass:"title"},[_vm._v("行为热力图")]),_c('ul',{staticClass:"status"},[_c('li',[_c('span',{staticStyle:{"background":"#67c23a"}}),_c('span',[_vm._v("积极")])]),_c('li',[_c('span',{staticStyle:{"background":"#f56c6c"}}),_c('span',[_vm._v("消极")])]),_c('li',[_c('span',{staticStyle:{"background":"#f2f6fc"}}),_c('span',[_vm._v("无状态")])])])]),_c('div',{staticClass:"scree_echarts"},[(_vm.selDateTimeLine)?_c('ThermodynamicChart',{attrs:{"datestart":_vm.selDateTimeLine.Date.timeFormat('yyyy-MM-dd 00:00:01'),"dateend":_vm.selDateTimeLine.Date.timeFormat('yyyy-MM-dd 23:59:59'),"UsId":_vm.selDateTimeLine.UsId,"teamId":_vm.selDateTimeLine.teamId,"IsRealTimeScreenshot":_vm.IsRealTimeScreenshot}}):_vm._e()],1)]),(
          _vm.IsRealTimeScreenshot &&
          _vm.selDateTime &&
          _vm.selDateTime.filter(function (m) { return m.Img; }).length
        )?_c('div',{staticClass:"scree"},[_c('div',{staticClass:"scree_title"},[_c('span',{staticClass:"title"},[_vm._v("定期截图")])]),(_vm.selDateTime && _vm.selDateTime.filter(function (m) { return m.Img; }).length)?_c('div',[_c('viewer',{staticClass:"screephot",attrs:{"images":_vm.selDateTime.filter(function (m) { return m.Img; })}},_vm._l((_vm.selDateTime.filter(function (m) { return m.Img; })),function(itempic,picindex){return _c('div',{key:picindex,staticClass:"screen"},[_c('img',{staticStyle:{"width":"157px","height":"88px"},attrs:{"src":_vm.cmurl + itempic.Img,"alt":itempic.FocusFromName}}),_c('p',{staticClass:"time"},[_vm._v(_vm._s(itempic.LineTime))])])}),0)],1):_c('div',{staticClass:"nodata"},[_c('i',{staticClass:"hiFont hi-wushuju"}),_c('p',[_vm._v("暂无数据")])])]):_vm._e()])]),_c('progresscom',{attrs:{"name":'staffDataPic',"activeBar":_vm.selRow,"IsRealTimeScreenshot":_vm.IsRealTimeScreenshot}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }