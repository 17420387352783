//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    XModal: () => import("@/components/XModal"),
    ThermodynamicChart: () => import("@/components/ThermodynamicChart"),
    progresscom: () => import("@/userview/data-analysis/progressCom"),
    tooltip: () => import("@/components/textTooltip"),
  },
  props: {
    //是否显示截图
    IsRealTimeScreenshot: {
      type: Boolean,
      default: true,
    },
    selDateTimeLine: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selRow: null, //选择的软件
      selDateTime: null,
      loading: false,
    };
  },
  computed: {
    cmurl() {
      return process.env.VUE_APP_CMURL;
    },
  },
  methods: {
    /**
     * 弹窗打开
     */
    opened() {
      this.$nextTick(() => {
        this.loading = true;
        this.$http
          .post(
            "/Attendance/GetMyWorkStatusInHalfHour.ashx",
            this.selDateTimeLine
          )
          .then((resp) => {
            if (resp.res == 0) {
              this.selDateTime = resp.data;
            }
          })
          .finally(() => (this.loading = false));
      });
    },
    /**
     * 某个软件截图
     */
    handleAppPic(app) {
      this.selRow = {
        name: app.PName,
        fromList: app.fromList,
      };
      this.$nextTick(() => {
        this.$modal.show("staffDataPic");
      });
    },
  },
};
